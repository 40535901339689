<template>
    <v-container
        fluid
        class="fill-height"
    >
        <v-row justify="center">
            <v-col
                cols="12"
                md="4"
            >
                <v-form
                    ref="requestPasswordResetForm"
                    v-model="valid"
                >
                    <v-card
                        tile
                        elevation="5"
                    >
                        <v-card-title class="py-1">
                            {{ $t('customerportal-forgotPassword') }}
                        </v-card-title>
                        <v-card-subtitle class="py-3">
                            {{ $t('customerportal-forgotPassword-infoBox') }}
                        </v-card-subtitle>
  
                        <v-card-text class="ma-0 py-0">
                            <v-text-field
                                v-model="email"
                                tabindex="1"
                                :rules="globalEmailValidator(email)"
                                :label="$t('myuser/profile-email')"
                                required
                            />

                            <template v-for="code in requestCodes">
                                <v-alert
                                    :key="code"
                                    class="ma-0 mb-1"
                                    dense
                                    :type="requestStatus ? 'success' : 'error'"
                                >
                                    <template v-if="requestStatus">
                                        {{ $t('success-' + code) }}
                                    </template>
                                    <template v-else>
                                        {{ $t('error-' + code) }}
                                    </template>
                                </v-alert>
                            </template>
                        </v-card-text>

                        <v-card-actions>
                            <v-container>
                                <v-row dense>
                                    <v-col>
                                        <v-btn
                                            :to="{ name: 'Login'}"
                                        >
                                            {{ $t('button-text-login') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-btn
                                            :disabled="!valid"
                                            tabindex="2"
                                            type="submit"
                                            @click="ClickRequestPasswordReset"
                                        >
                                            {{ $t('sendMail') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex'


export default {
    data() {
        return {
            valid: false,

            email: null,
            requestStatus: false,
            requestCodes: []
        }
    },
    computed: {
    },
    created() {        
    },    
    methods: {
        ClickRequestPasswordReset(e){

            //Important when using 'history' mode in router
            e.preventDefault()

            this.requestCodes = []
            this.requestStatus = false
            this.RequestPasswordReset({
                email: this.email
            })
                .then(data => {
                    if(data.success){
                        this.$refs.requestPasswordResetForm.resetValidation()
                        this.email = null
                    }     
                    
                    this.requestStatus = data.success
                    this.requestCodes = data.codes
                })
        },
        ...mapActions({
            RequestPasswordReset: 'RequestPasswordReset'
        })
    }
}
</script>
