import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VForm,{ref:"requestPasswordResetForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{attrs:{"tile":"","elevation":"5"}},[_c(VCardTitle,{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.$t('customerportal-forgotPassword'))+" ")]),_c(VCardSubtitle,{staticClass:"py-3"},[_vm._v(" "+_vm._s(_vm.$t('customerportal-forgotPassword-infoBox'))+" ")]),_c(VCardText,{staticClass:"ma-0 py-0"},[_c(VTextField,{attrs:{"tabindex":"1","rules":_vm.globalEmailValidator(_vm.email),"label":_vm.$t('myuser/profile-email'),"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._l((_vm.requestCodes),function(code){return [_c(VAlert,{key:code,staticClass:"ma-0 mb-1",attrs:{"dense":"","type":_vm.requestStatus ? 'success' : 'error'}},[(_vm.requestStatus)?[_vm._v(" "+_vm._s(_vm.$t('success-' + code))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('error-' + code))+" ")]],2)]})],2),_c(VCardActions,[_c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VBtn,{attrs:{"to":{ name: 'Login'}}},[_vm._v(" "+_vm._s(_vm.$t('button-text-login'))+" ")])],1),_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"disabled":!_vm.valid,"tabindex":"2","type":"submit"},on:{"click":_vm.ClickRequestPasswordReset}},[_vm._v(" "+_vm._s(_vm.$t('sendMail'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }